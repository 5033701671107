<template>
  <div class="login">
    <div class="confid">
      <div :style="{ textAlign: 'center' }">
        <img src="@/assets/img/logo2.png" alt="" />
      </div>
      <p :style="{ textAlign: 'center' }">欢迎登录腾云管家大数据SaaS服务系统</p>
      <div class="password">
        <div class="account"><span>账号</span></div>
        <div :class="userinput ? 'userinputbox active' : 'userinputbox'">
          <input
            v-model="userName"
            class="userinputbox_input"
            type="text"
            placeholder="请输入用户名"
            @focus="userFocus"
            @blur="userBlur"
          />
        </div>
        <div class="account" />
        <div :class="passinput ? 'userinputbox active' : 'userinputbox'">
          <input
            v-model="password"
            id="password"
            class="userinputbox_input passInputCla"
            :type="pwdType"
            maxlength="20"
            placeholder="请输入密码"
            @focus="passFocus"
            @blur="passBlur"
          />
        </div>
        <img v-if="pwdType=='password'" class="eye" src="@/assets/img/no_eye.png"  width="20px" height="20px" alt="" @click="showPwd">
        <img v-else src="@/assets/img/eye.png" class="eye" width="20px" height="20px" alt="" @click="showPwd">
        <div class="account" />
        <div :class="codeinput ? 'userinputbox active' : 'userinputbox'">
          <input
            v-model="codeword"
            class="userinputbox_input"
            type="codeword"
            maxlength="11"
            placeholder="请输入验证码"
            @focus="codeFocus"
            @blur="codeBlur"
          />
        </div>
        <img class="codeImg" :src="verify" mode="" @click="changeCodeImg" />
        <el-popover
          placement="top-start"
          title="忘记密码"
          width="200"
          trigger="click"
          content="请联系奥普生物重置密码">
          <p style="textAlign:right;cursor:pointer;" v-if="isBindWx == 0" slot="reference" >忘记密码?</p>
        </el-popover>
        
        <!-- <div class="register" :style="{ margin: '15px auto' }" @click="tologin">
          {{ isBindWx == 0 ? '登录' : '绑定' }}
        </div> -->
        <el-button type="primary" class="register" :loading="login_disabled" :disabled="login_disabled"  @click="tologin"> {{ isBindWx == 0 ? '登录' : '绑定' }}</el-button>
      </div>
      <div class="Cooperation" v-if="isBindWx == 0">
        或使用合作账号登录
        <div class="line" />
      </div>
      <div
        :style="{ textAlign: 'center', marginTop: '35px' }"
        v-if="isBindWx == 0"
      >
        <img src="../../assets/img/wx.png" alt="" @click="wechat_url" />
      </div>
    </div>

    <div class="bottom" @click="goICP">
      腾云管家大数据+AI人工智能版权所有
      Copyright2017沪ICP备12044172 号-2版本号V1.0.18
    </div>
  </div>
</template>

<script>
import {addMenu } from '@/router/menuMain.js';

import api from '@/api/login'
import axios from 'axios';
import { getCity2 } from '@/api/search.js'
import { setToken } from '@/utils/auth'
export default {
  data() {
    return {
      userinput: false,
      passinput: false,
      codeinput: false,
      login_disabled: false,
      pwdType: 'password',
      userName: '',
      password: '',
      codeword: '',
      uuid: '',
      verify: '',
      verify_key: '',
      choseXy: false,
      appid: 'wx39ba1df6661fe9e5',
      isBindWx: 0, //绑定微信： 0-是 1-否
    }
  },
  created() {
    this.genImgCode();
    // 页面监听键盘事件
    this.keyDown();
   
  },
  mounted() {
    this.uuid = this.guid()
    let query = this.$route.query
    if (query.code) {
      this.wxLogin(query)
    }
     // 初始化数据
     let loading = this.$loading({
      lock: true,
      text: '',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)',
    })
    loading.close()

  },
  methods: {
    S4() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
    },
    guid() {
      return (
        this.S4() +
        this.S4() +
        '-' +
        this.S4() +
        '-' +
        this.S4() +
        '-' +
        this.S4() +
        '-' +
        this.S4() +
        this.S4() +
        this.S4()
      )
    },
    genImgCode() {
      this.codeword = '';
      api
        .refresh({})
        .then((res) => {
          if (res.code === 200) {
            this.verify = process.env.VUE_APP_BASE_API + res.data.image_url
            this.verify_key = res.data.key
          }
        })
        .catch((err) => {})
    },
    keyDown() {
       document.onkeydown = e => {
         //事件对象兼容
         let e1 = e || event || window.event || arguments.callee.caller.arguments[0]
         if (e1 && e1.keyCode == 13) {
           this.tologin();
         }
       }
    },
    // 用户名输入框获取焦点
    userFocus() {
      this.userinput = true
    },
    // 用户名输入框失去焦点
    userBlur() {
      this.userinput = false
    },
    // 密码输入框获取焦点
    passFocus() {
      this.passinput = true
    },
    // 密码输入框失去焦点
    passBlur() {
      this.passinput = false
    },
    // 密码输入框获取焦点
    codeFocus() {
      this.codeinput = true
    },
    // 密码输入框失去焦点
    codeBlur() {
      this.codeinput = false
    },
    // 清空密码输入
    clearUserName() {
      this.userName = ''
    },
    // 改变验证码图片
    changeCodeImg() {
      this.genImgCode()
    },
    // 密码可见
    showPwd() {
      if(this.pwdType === 'password'){
        this.pwdType = 'text'
      }else{
        this.pwdType = 'password'
      }
    },
    // 登录
    tologin() {
      this.login_disabled = true;
      if (this.isBindWx == 0) {
        this.loginFun()
      } else if (this.isBindWx == 1) {
        this.binding_wechat()
      }
    },

    // 登录的页面
    loginPage(is_redirect) {
      if(is_redirect){
        this.$router.push({ name: 'changePassword',query: {is_redirect: 1} })
      }else{
        // this.getCity2Fun();
        api.user_permission().then(res=> {
          localStorage.setItem('menuList', JSON.stringify(res.data))
          addMenu(res.data,true)
        }).finally(msg => {
          this.login_disabled = false;
        })
        // this.$router.push({ name: 'SearchPage' })
      }

    },
    // 账号登录
    loginFun() {
      // 小写转换为大写
      const codeWord = this.codeword.toUpperCase()
      api
        .login({
          phone: this.userName,
          // password: this.password,
          password: this.$md5(this.password),
          verify_value: codeWord,
          verify_key: this.verify_key,
        })
        .then((res) => {
          if (res.code == 200) {
            setToken(res.data.token)
            let userInfo = {
              user_name: res.data.user_name,
              user_id: res.data.user_id,
              ...res.data.user_info,
            }
            localStorage.setItem('userInfo', JSON.stringify(userInfo))
            this.loginPage(res.data.is_redirect);
            // this.$router.push({ name: 'SearchPage' })
          }
        })
        .catch((err) => {
          this.login_disabled = false;
          this.changeCodeImg();
        })
    },

    // 微信登录
    wxLogin(query) {
      // this.getOpenId(query.code);
      // return;
      // this.getOpenId(query.code).then(res => {
      //   const openidData = res.data;
        api.wechat_login({
          // open_id: openidData.openid,
          // refresh_token: openidData.refresh_token,
          code: query.code,
          state: query.state,
          login_type: 0, //登录方式,web:0,app:1
        })
        .then(
          (res) => {
            if (res.code == 200) {
               if (res.code == 200) {
                setToken(res.data.token)
                let userInfo = {
                  user_name: res.data.user_name,
                  user_id: res.data.user_id,
                  ...res.data.user_info,
                }
                localStorage.setItem('userInfo', JSON.stringify(userInfo))
                this.loginPage(res.data.is_redirect);
                // this.$router.push({ name: 'SearchPage' })
              }

            } else if (res.code == 201) {
            } else if (res.code == 3001) {
              this.isBindWx = 1
              this.access_token = res.data.access_token
              this.refresh_token = res.data.refresh_token
            }
          },
          (err) => {
            if (err.code == 3001) {
              this.isBindWx = 1
              this.access_token = err.data.access_token
              this.refresh_token = err.data.refresh_token
            } else if (err.code == 201) {
            }
          },
        )

      // })
      
    },
    // 根据code获取openid
    getOpenId(code) {
      this.$jsonp(
        `https://api.weixin.qq.com/sns/oauth2/access_token?appid=wx39ba1df6661fe9e5&secret=e91d237dffc6914c9d227aeb10822f87&grant_type=authorization_code&code=${code}&jsonpcallback=jsonpFunc`,
        {
          callbackQuery: 'jsonpcallback',
          //指定回调的查询字符串的名称（默认callback）,后端可能自定义，比如当前案例约定的是jsonpcallback
          callbackName: 'jsonpFunc' //回调函数方法名称
       }
      ).then(data => {
      });
      // const response = await this.$jsonp(`https://api.weixin.qq.com/sns/oauth2/access_token?appid=wx39ba1df6661fe9e5&secret=e91d237dffc6914c9d227aeb10822f87&grant_type=authorization_code&code=${code}`,{
      //     callbackQuery:'callbackParam', //一定要加这两个参数
      //     callbackName:'jsonpCallback' //一定要加这两个参数,要不然会报错 导致代码阻塞
      // })
    
      // const service = axios.create({
      //   baseURL: 'https://api.weixin.qq.com',
      //   withCredentials: true,
      //   timeout: 5000
      // })
      // let params = {
      //   appid: this.appid,
      //   secret: this.secret,
      //   code,
      //   grant_type: 'authorization_code'
      // }
      // return service({
      //   url: '/sns/oauth2/access_token',
      //   method: 'get',
      //   params,
      // })
    },

    // 微信登录用于与系统进行绑定
    binding_wechat() {
      const codeWord = this.codeword.toUpperCase()
      api
        .binding_wechat({
          phone: this.userName,
          // password: this.password,
          password: this.$md5(this.password),
          verify_value: codeWord,
          verify_key: this.verify_key,
          login_type: 0,//登录方式:APP:login_type = 1,Web:login_type = 0
          access_token: this.access_token, //微信登录会话凭证
          refresh_token: this.refresh_token, //刷新token凭证
        })
        .then((res) => {
          if (res.code == 200) {
            setToken(res.data.token)
            let userInfo = {
              user_name: res.data.user_name,
              user_id: res.data.user_id,
            }
            localStorage.setItem('userInfo', JSON.stringify(userInfo))
            this.loginPage(res.data.is_redirect);
            // this.$router.push({ name: 'SearchPage' })
          }
        }).catch(err => {
          this.login_disabled = false;
        })
    },

    // 获取省份，城市
    getCity2Fun() {
      getCity2().then((res) => {
        let arr = []
        res.data.forEach((v) => {
          let arr2 = []
          v.city_list.forEach((e) => {
            arr2.push({
              value: e.city_id,
              label: e.city_name,
            })
          })
          arr.push({
            value: v.province_id,
            label: v.province_name,
            city_list: arr2,
          })
        })
        localStorage.setItem('province', JSON.stringify(arr)) //缓存省份
      })
    },

    // 获取到code和state微信登录
    wechat_url() {
      api
        .wechat_url({})
        .then((res) => {
          if (res.code === 200) {
            window.location.href = res.data.login_url
          }
        })
        .catch((err) => {})
    },
    // 跳转到icp备案页面
    goICP() {
       window.open('https://beian.miit.gov.cn/#/Integrated/index', '_blank');
    }
  },
}
</script>

<style lang="scss" scoped>
.login {
  width: 100%;
  height: 100vh;
  font-size: 14px;
  background: url(../../assets/img/bg.png);
  overflow: hidden;
  .confid {
    padding: 45px;
    width: 410px;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: -1px 12px 35px 0px rgba(36, 86, 169, 0.21);
    opacity: 0.8;
    border-radius: 8px;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .bottom {
    height: 15px;
    font-size: 14px;
    position: absolute;
    cursor: pointer;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    color: #959da8;
    line-height: 36px;
  }
}
.account {
  margin-top: 20px;
  width: 70px;
  font-size: 14px;
  color: #00afe9;
  line-height: 36px;
  display: flex;
}
input {
  outline: none;
  border: none;
  border-bottom: 1px solid #ccc;
  width: 350px;
  height: 20px;
  margin-bottom: 10px;
}
input:focus {
  border-bottom: 1px solid #00afe9;
}
.password {
  width: 350px;
  position: relative;
  left: 50%;
  height: 230px;
  transform: translateX(-50%);
}
element.style {
  text-align: right;
  width: 63px;
  height: 14px;
  font-size: 14px;
}
.register {
  width: 350px;
  height: 42px;
  margin: 15px auto;
  text-align: center;
  color: #ffffff;
  background: linear-gradient(90deg, #5cdae3, #50b7eb);
  box-shadow: 0px 6px 12px 0px #d3ddf1;
  border-radius: 5px;
  cursor: pointer;
  border: 0;
}
.register:hover {
  opacity: 0.8;
}
.register:active {
  opacity: 1;
}

.userinputbox {
  display: flex;
  .passInputCla[type="password"]::-ms-reveal {
    display: none;
  }
}


.codeImg {
  position: absolute;
  left: 270px;
  top: 120px;
  width: 74px;
  height: 28px;
}
.eye {
  position: absolute;
  right: 20px;
  top: 80px;
}
.Cooperation {
  width: 126px;
  height: 34px;
  font-size: 14px;
  padding: 0px 20px;
  background-color: #fff;
  opacity: 0.8;
  top: 30px;
  color: #999999;
  line-height: 36px;
  text-align: center;
  margin: 0 auto;
  position: relative;
  .line {
    position: absolute;
    z-index: -1;
    width: 351px;
    height: 1px;
    background: #eef0f6;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
  }
}
</style>
