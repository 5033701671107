import request from '@/utils/request'

//
const api = {
  // 验证码
  refresh(data) {
    return request({
      url: '/captcha/refresh/',
      method: 'get',
      params: data
    })
  },
  login(data) {
    return request({
      url: '/api/login/',
      method: 'POST',
      data: data
    })
  },
  // // 订单页面
  // orders(data) {
  //   return request({
  //     url: '/api/orders/',
  //     method: 'get',
  //     params: data
  //   })
  // },
  // 微信登录web-用于获取code
  wechat_url(data) {
    return request({
      url: '/api/wechat_url/',
      method: 'get',
      params: data
    })
  },
  // 微信登录 web/APP
  wechat_login(data) {
    return request({
      url: '/api/wechat_login/',
      method: 'get',
      params: data
    })
  },
  // 微信登录用于与系统进行绑定
  binding_wechat(data) {
    return request({
      url: '/api/binding_wechat/',
      method: 'post',
      data
    })
  },
  // 获取个人信息
  userinfo() {
    return request({
      url: '/api/userinfo/',
      method: 'get',
    })
  },
  // 获取用户对应的路由信息
  user_permission() {
    return request({
      url: '/api/user_permission/',
      method: 'get',
    })
  },

}
export default api
